// import { CloudArrowUpIcon, LockClosedIcon, ServerIcon } from '@heroicons/react/20/solid'
import FreeDemoRegistration from "../FreeDemoRegistration";
import BootstrapFAQS from "../FAQ/BootstrapFAQS";

export default function BootstrapPage() {
  return (
    <div className="relative isolate overflow-hidden bg-white px-6 py-24 sm:py-32 lg:overflow-visible lg:px-0">
      <div className="absolute inset-0 -z-10 overflow-hidden"></div>
      <div className="mx-auto grid max-w-2xl grid-cols-1 gap-x-8 gap-y-16 lg:mx-0 lg:max-w-none lg:grid-cols-2 lg:items-start lg:gap-y-10">
        <div className="lg:col-span-2 lg:col-start-1 lg:row-start-1 lg:mx-auto lg:grid lg:w-full lg:max-w-7xl lg:grid-cols-2 lg:gap-x-8 lg:px-8">
          <div className="lg:pr-4">
            <div className="lg:max-w-lg">
              <p className="text-base font-semibold leading-7 text-indigo-600">
                Dream Adoration
              </p>
              <h1 className="mt-2 text-3xl font-bold tracking-tight text-gray-900 sm:text-4xl">
                Bootstrap
              </h1>
              <h2 className="mt-6 text-xl leading-8 text-gray-700">
                At Dream Adoration, participants learn to set up an
                understanding of the basics using Bootstrap classes: a great
                framework for styling and designing your web pages with much
                ease. Here, participants discover the very basis of
                Bootstrap-through its grid system, components, utilities, and
                principles of responsive designs-allowing them to craft
                responsive and visually appealing web layouts efficiently.
                Whether you are new to the industry or looking to update your
                existing skills, our Bootstrap classes provide you with a
                comprehensive learning experience. Join us, and unlock the power
                of Bootstrap to establish and enhance aesthetics and user
                experience in your web applications.
              </h2>
            </div>
          </div>
        </div>
        <div
          className="lg:col-start-2 lg:row-span-2 lg:row-start-1"
          style={{ margin: "0rem" }}
        >
          <FreeDemoRegistration />
        </div>

        <div className="lg:col-span-2 lg:col-start-1 lg:row-start-2 lg:mx-auto lg:grid lg:w-full lg:max-w-7xl lg:grid-cols-2 lg:gap-x-8 lg:px-8">
          <div className="lg:pr-4">
            <div className="max-w-xl text-base leading-7 text-gray-700 lg:max-w-lg">
              <p>
                Joining Dream Adoration certainly provides a great many
                benefits. This online learning resource is perfect and
                adaptable, covering all sorts of topics regarding programming
                languages, web developments, and whatnot. Engage in mentorship
                from expert instructors, exciting interactive learning
                materials, and a plethora of hands-on projects to add on to your
                skills for the betterment of your career. So whether you are a
                new learner or a trained professional, Dream Adoration offers
                the safe haven to foster your learning process and move further
                forward toward your goals. Join us today and have the powers of
                technology and innovation waiting just for you.
              </p>
            </div>
          </div>
        </div>
      </div>
      <BootstrapFAQS />
    </div>
  );
}
