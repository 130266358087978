import React, { useState, useEffect } from "react";
import Preloader from "./Preloader";
import $ from "jquery";
import Toast from "./Toast"; // Import the Toast component
// import ReCAPTCHA from "react-google-recaptcha";

export default function Modal({ isOpen, onClose }) {
  const [formData, setFormData] = useState({
    name: "",
    email: "",
    number: "",
    course: "",
    state: "",
    uniqueKey: "",
  });
  const [loading, setLoading] = useState(true);
  const [errors, setErrors] = useState({});
  const [toast, setToast] = useState({ show: false, message: "", type: "" }); // State for toast

  useEffect(() => {
    fetchUniqueKey();
  }, []);

  // Function to fetch the unique key from the server
  const fetchUniqueKey = async () => {
    try {
      const response = await fetch("http://example.com/getUniqueKey");
      const data = await response.json();
      setFormData((prevData) => ({
        ...prevData,
        uniqueKey: data.key,
      }));
      setLoading(false);
    } catch (error) {
      console.error("Error fetching unique key:", error);
      setLoading(false);
    }
  };

  const handleChange = (e) => {
    const { name, value } = e.target;

    setFormData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
    // Clear the error message if the field is not empty
    if (value.trim() !== "") {
      setErrors((prevErrors) => ({
        ...prevErrors,
        [name]: "",
      }));
    }
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    const errors = {};

    // Check for empty fields
    for (const key in formData) {
      if (!formData[key].trim()) {
        errors[key] = "This field is required";
      }
    }
    // Validate email format
    const emailPattern = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
    if (!emailPattern.test(formData.email) && formData.email.trim() !== "") {
      errors.email = "Please enter a valid email address.";
    }

    const namePattern = /^[a-zA-Z,\s]{0,20}$/;
    if (
      !namePattern.test(formData.name.trim()) &&
      formData.name.trim() !== ""
    ) {
      errors.name = "20 characters, no specials or numbers.";
    }

    const statePattern = /^[a-zA-Z,\s]{0,18}$/;
    if (
      !statePattern.test(formData.state.trim()) &&
      formData.state.trim() !== ""
    ) {
      errors.state = "18 characters, no specials or numbers.";
    }

    const coursePattern = /^[a-zA-Z,\s]{0,25}$/;
    if (
      !coursePattern.test(formData.course.trim()) &&
      formData.course.trim() !== ""
    ) {
      errors.course = "25 characters, no specials or numbers.";
    }

    const numberPattern = /^\+?\d{0,11}$/;
    if (
      !numberPattern.test(formData.number.trim()) &&
      formData.number.trim() !== ""
    ) {
      errors.number = "Max 12 characters, only numbers and one '+'.";
    }

    if (Object.keys(errors).length > 0) {
      setErrors(errors);
    } else {
      console.log("Form submitted:", formData);
      onClose();
    }

    if (
      formData.name !== "" &&
      formData.email !== "" &&
      formData.number !== "" &&
      formData.course !== "" &&
      formData.state !== ""
    )
      setLoading(true);
    {
      $.ajax({
        url: "/webservices/modal-form-data.php",
        type: "POST",
        data: {
          name: formData.name,
          email: formData.email,
          number: formData.number,
          course: formData.course,
          state: formData.state,
          action: "free_learning",
        },
        success: function (response) {
          setLoading(false);
          setToast({ show: true, message: response, type: "success" });
          setTimeout(() => {
            setToast({ show: false, message: "", type: "" }); // Hide the toast after 5 seconds
            window.location.reload();
          }, 3000); // Reload the page after 3 seconds
        },
        error: function (xhr, status, error) {
          setLoading(false);
          setToast({
            show: true,
            message: "Please try again after some time !",
            type: "error",
          });
        },
      });
    }
  };

  if (!isOpen) return null;

  return (
    <div className="fadeOut">
      <div>
        {loading ? ( // Show preloader if loading is true
          <Preloader />
        ) : (
          <div className="justify-center items-center flex overflow-x-hidden overflow-y-auto fixed inset-0 z-50 outline-none focus:outline-none">
            <div className="relative w-auto my-6 mx-auto max-w-3xl lg:w-[27rem]">
              {/*content*/}
              <div className="border-0 rounded-lg shadow-lg relative flex flex-col w-full bg-white outline-none focus:outline-none">
                {/*header*/}
                <div className="flex items-start justify-between p-2 border-b border-solid border-blueGray-200 rounded-t">
                  <h1 className="text-3xl font-semibold">Register&nbsp;</h1>
                  <h2 className="text-3xl font-semibold"> For Free Demo</h2>
                </div>
                {/*body*/}
                <form
                  className="space-y-4 md:space-y-6 text-left"
                  action="#"
                  style={{ margin: "1rem" }}
                  onSubmit={handleSubmit}
                >
                  <div>
                    <label
                      htmlFor="name"
                      className="block mb-2 text-sm font-medium text-gray-900 dark:text-white"
                    >
                      Name
                    </label>
                    <input
                      type="text"
                      name="name"
                      id="name"
                      className="bg-gray-50 border border-gray-300 text-gray-900 sm:text-sm rounded-lg focus:ring-primary-600 focus:border-primary-600 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                      placeholder="Name"
                      required
                      value={formData.name}
                      onChange={handleChange}
                    />
                    {/* Error message for name field */}
                    <span style={{ color: "red" }}>{errors.name}</span>
                  </div>
                  <div>
                    <label
                      htmlFor="name"
                      className="block mb-2 text-sm font-medium text-gray-900 dark:text-white"
                    >
                      Email
                    </label>
                    <input
                      type="mail"
                      name="email"
                      id="email"
                      className="bg-gray-50 border border-gray-300 text-gray-900 sm:text-sm rounded-lg focus:ring-primary-600 focus:border-primary-600 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                      placeholder="Email"
                      required
                      value={formData.email}
                      onChange={handleChange}
                    />
                    {/* Error message for email field */}
                    <span style={{ color: "red" }}>{errors.email}</span>
                  </div>

                  <div>
                    <label
                      htmlFor="course"
                      className="block mb-2 text-sm font-medium text-gray-900 dark:text-white"
                    >
                      State
                    </label>
                    <input
                      type="text"
                      name="state"
                      id="state"
                      className="bg-gray-50 border border-gray-300 text-gray-900 sm:text-sm rounded-lg focus:ring-primary-600 focus:border-primary-600 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                      placeholder="State"
                      required
                      value={formData.state}
                      onChange={handleChange}
                    />
                    {/* Error message for course field */}
                    <span style={{ color: "red" }}>{errors.state}</span>
                  </div>

                  <div>
                    <label
                      htmlFor="name"
                      className="block mb-2 text-sm font-medium text-gray-900 dark:text-white"
                    >
                      Number
                    </label>
                    <input
                      type="text"
                      name="number"
                      id="number"
                      className="bg-gray-50 border border-gray-300 text-gray-900 sm:text-sm rounded-lg focus:ring-primary-600 focus:border-primary-600 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                      placeholder="Number"
                      required
                      value={formData.number}
                      onChange={handleChange}
                      oninput="this.value = this.value.replace(/[^0-9]+/g, '')"
                    />
                    {/* Error message for number field */}
                    <span style={{ color: "red" }}>{errors.number}</span>
                  </div>
                  {/* Other input fields go here */}
                  <div>
                    <label
                      htmlFor="course"
                      className="block mb-2 text-sm font-medium text-gray-900 dark:text-white"
                    >
                      Course
                    </label>
                    <input
                      type="text"
                      name="course"
                      id="course"
                      className="bg-gray-50 border border-gray-300 text-gray-900 sm:text-sm rounded-lg focus:ring-primary-600 focus:border-primary-600 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                      placeholder="Course"
                      required
                      value={formData.course}
                      onChange={handleChange}
                    />
                    {/* Error message for course field */}
                    <span style={{ color: "red" }}>{errors.course}</span>
                  </div>
                  {/*footer*/}

                  <div className="flex items-center justify-end p-1 border-t border-solid border-blueGray-200 rounded-b">
                    <button
                      className="text-red-500 background-transparent font-bold uppercase px-6 py-2 text-sm outline-none focus:outline-none mr-1 mb-1 ease-linear transition-all duration-150"
                      type="button"
                      onClick={onClose}
                    >
                      Close
                    </button>

                    {/* <ReCAPTCHA
                    sitekey="6LeIxAcTAAAAAJcZVRqyHh71UMIEGNQ_MXjiZKhI"
                    onChange={onChange}
                  /> */}

                    <input
                      type="hidden"
                      name="uniqueKey"
                      value={formData.uniqueKey}
                    />
                    <button
                      className="bg-emerald-500 text-white active:bg-emerald-600 font-bold uppercase text-sm px-6 py-2 rounded shadow hover:shadow-lg outline-none focus:outline-none mr-1 mb-1 ease-linear transition-all duration-150"
                      type="submit"
                      // disabled={!veryfied}
                    >
                      Submit
                    </button>
                  </div>
                </form>
              </div>
            </div>
          </div>
        )}
        {toast.show && <Toast message={toast.message} type={toast.type} />}{" "}
        <div className="opacity-25 fixed inset-0 z-40 bg-black"></div>
      </div>
    </div>
  );
}
