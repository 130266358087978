import React from "react";
import podcast from "./Podcast/ebook_dreamadoration.mp3";
import logo from "./Image/DreamLogo.png";

function Podcast() {
  return (
    <div>
      <div className="container min-w-100px w-auto max-w-400 mx-auto mb-4">
        <div className="card flex flex-col items-center bg-gradient-to-tr from-blue-400 to-red-400 text-xl font-mono p-4 rounded-md text-white">
          <div className="cover flex flex-col items-center min-w-80px w-auto max-w-880px">
            <img src={logo} alt="Album Cover" className="w-2/6 rounded-2xl" />
            <h5 className="mb-2 text-2xl font-bold tracking-tight text-gray-200 dark:text-white">
              E-Book Podcast
            </h5>
            {/* <p className="-translate-y-0 w-4/6 text-center break-words text-md font-bold text-black">
              E-Book Podcast
            </p> */}
          </div>
          <audio id="song" className="block w-full max-w-md mx-auto" controls>
            <source src={podcast} type="audio/mp3" />
            Your browser does not support the audio element.
          </audio>
        </div>
      </div>
    </div>
  );
}

export default Podcast;
