import React from "react";
import htmlimg from "../Image/Book/javascript.jpg";
import DownloadEbookForm from "../DownloadEbookForm";

function JavaScriptBook() {
  return (
    <div
      style={{
        margin: "2rem",
        marginTop: "5rem",
        display: "flex",
        flexDirection: "column",
        alignItems: "center", // Center content horizontally
      }}
    >
      {/* Card */}
      <div
        style={{
          maxWidth: "100%",
          overflow: "hidden",
          marginBottom: "2rem",
          width: "100%",
        }}
      >
        <div
          className="flex flex-col items-center bg-white rounded-lg shadow md:flex-row hover:bg-gray-100 dark:border-gray-700 dark:bg-gray-800 dark:hover:bg-gray-700"
          style={{
            display: "flex",
            textDecoration: "none",
            color: "inherit",
          }}
        >
          <img
            className="object-cover w-full rounded-t-lg md:w-96 rounded-l-lg"
            src={htmlimg}
            alt=""
          />
          <div
            className="flex flex-col justify-between p-4 leading-normal"
            style={{ flex: "1" }}
          >
            <h1 className="mb-1 text-2xl font-bold text-gray-900 dark:text-white">
              JavaScript
            </h1>
            <h2 className="mb-3 text-xl font-bold text-gray-700 dark:text-gray-400">
              Author : Dr. Axel Rauschmayer
            </h2>
            <p className="mb-3 font-normal text-gray-600 dark:text-gray-400">
              Here's a description for JavaScript: Embark on a journey of From
              the book 'JavaScript Unleashed' about dynamic web development.
              limitless possibilities of JavaScript and give life to your web
              projects. A comprehensive guide equips you with the knowledge and
              skills to help them tap the full potential of JavaScript, From
              basic scripting to advanced techniques. Dive head-first into the
              world of of interactivity, animation, and dynamic content
              creation. Whether you are either a greenhorn or seasoned coder,
              "JavaScript Unleashed" is Go-to resource for mastering this
              versatile programming language. Elevate your web applications,
              captivate your audience and unleash the real power of JavaScript
              with 'JavaScript Unleashed.
            </p>

            <DownloadEbookForm />
          </div>
        </div>
      </div>

      {/* Download Form */}
      <div style={{ width: "100%", maxWidth: "500px" }}>
        {/* <DownloadEbookForm /> */}
      </div>
    </div>
  );
}

export default JavaScriptBook;
