import React, { useState } from "react";
import ebook from "./Data/Ebook.json";
// import { usePdf } from "@mikecousins/react-pdf";

import "./CSS/navbar.css";
import Podcast from "./Podcast";
import EbookMeta from "./Meta/EbookMeta";

function Ebook() {
  const [pdfUrl, setPdfUrl] = useState(""); // State to store PDF URL

  return (
    <div style={{ marginTop: "5rem" }}>
      <EbookMeta />
      <h2
        className="text-black"
        style={{ fontSize: "18px", textAlign: "center" }}
      >
        Dream Adoration
        <br />
        <span style={{ fontSize: "2rem" }}>
          Understanding the World of{" "}
          <span style={{ color: "red" }}>Dreams</span>
        </span>
      </h2>
      <Podcast />
      <div
        className="flex flex-wrap justify-center"
        style={{
          margin: "1rem",
          padding: "1rem",
          gap: "2rem",
        }}
      >
        {ebook.map((service, index) => (
          <div
            key={index}
            className="flex flex-col items-center bg-white border border-gray-200 rounded-lg shadow md:flex-row md:max-w-xl hover:bg-gray-100 dark:border-gray-700 dark:bg-gray-800 dark:hover:bg-gray-700"
          >
            <img
              className="w-full rounded-t-lg h-100 md:h-auto md:w-48 md:rounded-none md:rounded-s-lg"
              src={service.image}
              alt=""
            />
            <div className="flex flex-col justify-between p-4 leading-normal">
              <h5 className="mb-2 text-2xl font-bold tracking-tight text-gray-900 dark:text-white">
                {service.title}
              </h5>
              <p className="mb-2 font-normal text-gray-700 dark:text-gray-400">
                {service.Description}
              </p>
              <p className="mb-2 font-normal text-gray-700 dark:text-gray-400">
                <b> Author : {service.Author}</b>
              </p>

              <div className="flex justify-between w-full">
                <a href={service.href}>
                  <button
                    type="button"
                    className="px-3 py-2 text-xs font-medium text-center text-white bg-green-700 rounded-lg dark:bg-green-600 dark:hover:bg-green-700 dark:focus:ring-green-800 focus:outline-none"
                  >
                    Download
                  </button>
                </a>
              </div>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
}

export default Ebook;
