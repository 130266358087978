// import { CloudArrowUpIcon, LockClosedIcon, ServerIcon } from '@heroicons/react/20/solid'
import FreeDemoRegistration from "../FreeDemoRegistration";
import FAQHtml from "../FAQ/FAQHtml";

export default function HtmlPage() {
  return (
    <div className="relative isolate overflow-hidden bg-white px-6 py-24 sm:py-32 lg:overflow-visible lg:px-0">
      <div className="absolute inset-0 -z-10 overflow-hidden"></div>
      <div className="mx-auto grid max-w-2xl grid-cols-1 gap-x-8 gap-y-16 lg:mx-0 lg:max-w-none lg:grid-cols-2 lg:items-start lg:gap-y-10">
        <div className="lg:col-span-2 lg:col-start-1 lg:row-start-1 lg:mx-auto lg:grid lg:w-full lg:max-w-7xl lg:grid-cols-2 lg:gap-x-8 lg:px-8">
          <div className="lg:pr-4">
            <div className="lg:max-w-lg">
              <p className="text-base font-semibold leading-7 text-indigo-600">
                Dream Adoration
              </p>
              <h1 className="mt-2 text-3xl font-bold tracking-tight text-gray-900 sm:text-4xl">
                Hyper Text Markup Language
              </h1>
              <h2 className="mt-6 text-xl leading-8 text-gray-700">
                HTML classes were provided in Dream Adoration, underpinned to
                give an overview of how to do Hypertext Markup Language (HTML),
                pretty much a basic requirement when building and structuring
                web pages. Participants learn the main features of the syntax,
                tags, and elements of HTML so as to build well-structured and
                semantically correct web content. Our HTML classes welcome
                anyone who's just starting out in web development to experienced
                professionals who need further education to hone their skills.
                We provide you with quality hands-on experience and a learning
                environment that caters to your needs. Join us and discover the
                true power of HTML to infuse life into your web applications.
              </h2>
            </div>
          </div>
        </div>
        <div className="lg:col-start-2 lg:row-span-2 lg:row-start-1 lg:overflow-hidden">
          {/* <img
            className="w-[48rem] max-w-none rounded-xl bg-gray-900 shadow-xl ring-1 ring-gray-400/10 sm:w-[42rem]"
            src="Image/popularcourse/Learning/html.png"
            alt=""
          /> */}
          <FreeDemoRegistration />
        </div>

        <div className="lg:col-span-2 lg:col-start-1 lg:row-start-2 lg:mx-auto lg:grid lg:w-full lg:max-w-7xl lg:grid-cols-2 lg:gap-x-8 lg:px-8">
          <div className="lg:pr-4">
            <div className="max-w-xl text-base leading-7 text-gray-700 lg:max-w-lg">
              <p>
                Joining Dream Adoration comes with many benefits because it is
                an Versatile learning tool. Provides students access to many
                different courses covering various topics, including programming
                coding languages, web development, data science, and more.
                Explore Helpful, interactive tools, and informative guide
                experts projects for self-improvement of the skills in your
                career pathway, whether Whether a beginner or a professional,
                Dream Adoration provides a supportive environment to foster your
                Here's your learning journey and achieve your goals. Join us
                today and To unlock your potential in the field of technology
                and innovation.
              </p>
            </div>
          </div>
        </div>
      </div>
      <FAQHtml />
    </div>
  );
}
