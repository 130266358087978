// import { CloudArrowUpIcon, LockClosedIcon, ServerIcon } from '@heroicons/react/20/solid'
import FreeDemoRegistration from "../FreeDemoRegistration";
import MaterialUIFAQS from "../FAQ/MaterialUIFAQS";

export default function MaterailUIPage() {
  return (
    <div className="relative isolate overflow-hidden bg-white px-6 py-24 sm:py-32 lg:overflow-visible lg:px-0">
      <div className="absolute inset-0 -z-10 overflow-hidden"></div>
      <div className="mx-auto grid max-w-2xl grid-cols-1 gap-x-8 gap-y-16 lg:mx-0 lg:max-w-none lg:grid-cols-2 lg:items-start lg:gap-y-10">
        <div className="lg:col-span-2 lg:col-start-1 lg:row-start-1 lg:mx-auto lg:grid lg:w-full lg:max-w-7xl lg:grid-cols-2 lg:gap-x-8 lg:px-8">
          <div className="lg:pr-4">
            <div className="lg:max-w-lg">
              <p className="text-base font-semibold leading-7 text-indigo-600">
                Dream Adoration
              </p>
              <h1 className="mt-2 text-3xl font-bold tracking-tight text-gray-900 sm:text-4xl">
                Material UI
              </h1>
              <h2 className="mt-6 text-xl leading-8 text-gray-700">
                Materialize Mastery This course teaches you the best React
                component libraries in building stunning web interfaces in a
                beautiful and clean layout. We integrate our classes with the
                core ideas of Material-UI, covering every detail from its
                flexible layout system to vast collections of components that
                also includes theming capabilities and principles of responsive
                design. Using hands-on exercises and interactive tutorials, our
                participants learn how to bring out the full potential of
                Material-UI. This lets them craft incredibly modern and elegant
                web applications easily. Our courses on Material-UI are,
                therefore, best for anyone who intends to venture into this
                world of React development or wants streamlined UI development.
                Join us here at Materialize Mastery and build yourself in the
                role of creating highly elegant and intuitive user interfaces-an
                end-permitting sophistication evoking the heights of raising the
                overall end-user experience of your web projects. Let
                Materialize Mastery be your stepping stone to unlocking the
                transformative power of Material-UI to take your skills in
                developing web applications to new strata.
              </h2>
            </div>
          </div>
        </div>
        <div
          className="lg:col-start-2 lg:row-span-2 lg:row-start-1"
          style={{ margin: "0rem" }}
        >
          <FreeDemoRegistration />
        </div>

        <div className="lg:col-span-2 lg:col-start-1 lg:row-start-2 lg:mx-auto lg:grid lg:w-full lg:max-w-7xl lg:grid-cols-2 lg:gap-x-8 lg:px-8">
          <div className="lg:pr-4">
            <div className="max-w-xl text-base leading-7 text-gray-700 lg:max-w-lg">
              <p>
                Joining Dream Adoration offers many advantages because this is
                quite Versatile learning platform. It gives easy access to a
                vast amount of courses covering various topics, including
                programming languages, web development, data science and lots
                more. Go interact expert instructors, participatory learning
                materials, and experiential projects to improve your skills and
                advance in your profession. Whether whether you're an amateur or
                experienced expert, Dream Adoration provides the supportive
                atmosphere to help your learning journey and achieve your goals.
                Join us today and Discover your real potential in the face of
                the world and technology.
              </p>
            </div>
          </div>
        </div>
      </div>
      <MaterialUIFAQS />
    </div>
  );
}
