import React from "react";
import htmlimg from "../Image/Book/mysql.png";
import DownloadEbookForm from "../DownloadEbookForm";

function MySQlBook() {
  return (
    <div
      style={{
        margin: "2rem",
        marginTop: "5rem",
        display: "flex",
        flexDirection: "column",
        alignItems: "center", // Center content horizontally
      }}
    >
      {/* Card */}
      <div
        style={{
          maxWidth: "100%",
          overflow: "hidden",
          marginBottom: "2rem",
          width: "100%",
        }}
      >
        <div
          className="flex flex-col items-center bg-white rounded-lg shadow md:flex-row hover:bg-gray-100 dark:border-gray-700 dark:bg-gray-800 dark:hover:bg-gray-700"
          style={{
            display: "flex",
            textDecoration: "none",
            color: "inherit",
          }}
        >
          <img
            className="object-cover w-full rounded-t-lg md:w-96 rounded-l-lg"
            src={htmlimg}
            alt=""
          />
          <div
            className="flex flex-col justify-between p-4 leading-normal"
            style={{ flex: "1" }}
          >
            <h1 className="mb-1 text-2xl font-bold text-gray-900 dark:text-white">
              MySQL Mastery for Modern Databases
            </h1>
            <h2 className="mb-3 font-bold text-xl text-gray-700 dark:text-gray-400">
              Author : Aman Kumar
            </h2>
            <p className="mb-3 font-normal text-gray-600 dark:text-gray-400">
              Must have: Aman Kumar's "MySQL Mastery for Modern Databases".
              resource for any user wanting to unleash the full potential of
              MySQL for today's information-based world. This module covers
              everything, from the basics of database design to more advance
              queries Optimization techniques, and real-world applications.
              Whether be a fresher or even an old one, this book equips you with
              the skills to manage and scale modern databases with efficiency
              and accuracy. Find out how to design, maintain and secure your
              MySQL databases while leveraging the latest features and best
              practices in database management.
            </p>

            <DownloadEbookForm />
          </div>
        </div>
      </div>

      {/* Download Form */}
      <div style={{ width: "100%", maxWidth: "500px" }}>
        {/* <DownloadEbookForm /> */}
      </div>
    </div>
  );
}

export default MySQlBook;
