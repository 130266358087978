import React from "react";
import htmlimg from "../Image/Book/reactjs.jpg";
import DownloadEbookForm from "../DownloadEbookForm";

function ReactJsBook() {
  return (
    <div
      style={{
        margin: "2rem",
        marginTop: "5rem",
        display: "flex",
        flexDirection: "column",
        alignItems: "center", // Center content horizontally
      }}
    >
      {/* Card */}
      <div
        style={{
          maxWidth: "100%",
          overflow: "hidden",
          marginBottom: "2rem",
          width: "100%",
        }}
      >
        <div
          className="flex flex-col items-center bg-white rounded-lg shadow md:flex-row hover:bg-gray-100 dark:border-gray-700 dark:bg-gray-800 dark:hover:bg-gray-700"
          style={{
            display: "flex",
            textDecoration: "none",
            color: "inherit",
          }}
        >
          <img
            className="object-cover w-full rounded-t-lg md:w-96 rounded-l-lg"
            src={htmlimg}
            alt=""
          />
          <div
            className="flex flex-col justify-between p-4 leading-normal"
            style={{ flex: "1" }}
          >
            <h1 className="mb-1 text-2xl font-bold text-gray-900 dark:text-white">
              ReactJS
            </h1>
            <h2 className="mb-3 text-xl font-bold text-gray-700 dark:text-gray-400">
              Author : Chris Minnick
            </h2>
            <p className="mb-3 font-normal text-gray-600 dark:text-gray-400">
              Go on a transformative Enter the world of modern web development
              with 'Mastering'. React.js.' Discover the strength and flexibility
              of React.js, the cutting-edge library revolutionizing user
              interfaces. This This must-have reference gives you the tools and
              insights to build dynamic, interactive web applications with ease.
              Dive deep of component-based architecture, state management, and
              declarative UI design. Whether you're new to or experienced in the
              end A book that enables developers to master React.js. Potential
              of this revolutionary technology. Raise your web Developing
              capabilities while capturing your user and defining the future of
              the web with 'Mastering React.js'.
            </p>

            <DownloadEbookForm />
          </div>
        </div>
      </div>

      {/* Download Form */}
      <div style={{ width: "100%", maxWidth: "500px" }}>
        {/* <DownloadEbookForm /> */}
      </div>
    </div>
  );
}

export default ReactJsBook;
