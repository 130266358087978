import React from "react";
import htmlimg from "../Image/Book/Bootstrap.png";
import DownloadEbookForm from "../DownloadEbookForm";

function BootstrapBook() {
  return (
    <div
      style={{
        margin: "2rem",
        marginTop: "5rem",
        display: "flex",
        flexDirection: "column",
        alignItems: "center", // Center content horizontally
      }}
    >
      {/* Card */}
      <div
        style={{
          maxWidth: "100%",
          overflow: "hidden",
          marginBottom: "2rem",
          width: "100%",
        }}
      >
        <div
          className="flex flex-col items-center bg-white rounded-lg shadow md:flex-row hover:bg-gray-100 dark:border-gray-700 dark:bg-gray-800 dark:hover:bg-gray-700"
          style={{
            display: "flex",
            textDecoration: "none",
            color: "inherit",
          }}
        >
          <img
            className="object-cover w-full rounded-t-lg md:w-96 rounded-l-lg"
            src={htmlimg}
            alt=""
          />
          <div
            className="flex flex-col justify-between p-4 leading-normal"
            style={{ flex: "1" }}
          >
            <h1 className="mb-1 text-2xl font-bold text-gray-900 dark:text-white">
              Bootstrap Mastering Modern Web Design
            </h1>
            <h2 className="mb-3 font-bold text-gray-700 text-xl dark:text-gray-400">
              Author : Aman Kumar
            </h2>
            <p className="mb-3 font-normal text-gray-600 dark:text-gray-400">
              Bootstrap: Mastering Modern Web Design" is your bible for
              mastering one can make easy and responsive modern websites.
              Written by Aman Kumar, this book guides you through the basics of
              Bootstrap, probably the most used front-end framework, suitable
              for anyone and proficient programmers. Find out how to pen the
              most beautiful and mobile-friendly designs while accelerating your
              development process with pre-built modules. From navigation bars
              to grids and forms, this book covers everything you need to create
              professional, scalable websites efficiently. Dive into the world
              of Bootstrap and hone the skill of modern web design
            </p>

            <DownloadEbookForm />
          </div>
        </div>
      </div>

      {/* Download Form */}
      <div style={{ width: "100%", maxWidth: "500px" }}>
        {/* <DownloadEbookForm /> */}
      </div>
    </div>
  );
}

export default BootstrapBook;
