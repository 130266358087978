// import { CloudArrowUpIcon, LockClosedIcon, ServerIcon } from '@heroicons/react/20/solid'
import FreeDemoRegistration from "../FreeDemoRegistration";
import PythonFAQS from "../FAQ/PythonFAQS";

export default function PythonPage() {
  return (
    <div className="relative isolate overflow-hidden bg-white px-6 py-24 sm:py-32 lg:overflow-visible lg:px-0">
      <div className="absolute inset-0 -z-10 overflow-hidden"></div>
      <div className="mx-auto grid max-w-2xl grid-cols-1 gap-x-8 gap-y-16 lg:mx-0 lg:max-w-none lg:grid-cols-2 lg:items-start lg:gap-y-10">
        <div className="lg:col-span-2 lg:col-start-1 lg:row-start-1 lg:mx-auto lg:grid lg:w-full lg:max-w-7xl lg:grid-cols-2 lg:gap-x-8 lg:px-8">
          <div className="lg:pr-4">
            <div className="lg:max-w-lg">
              <p className="text-base font-semibold leading-7 text-indigo-600">
                Dream Adoration
              </p>
              <h1 className="mt-2 text-3xl font-bold tracking-tight text-gray-900 sm:text-4xl">
                Python
              </h1>
              <h2 className="mt-6 text-xl leading-8 text-gray-700">
                Dream Veneration In these classes, Python classes are provided
                to understand the fundamentals of Python, which a programmer
                needs to develop applications, scripts, and algorithms. In these
                classes, the participants will be taught the basics of Python
                syntax, data types, functions, and control flow, thus making it
                possible for them to write efficient and scalable solutions for
                software. Whether one is a novice learner seeking to begin their
                initiation into the world of programming or an expert
                professional looking to further sharpen one's skills, our Python
                classes will give you a deep learning experience that caters to
                your requirements. Explore with us the strength of Python in
                producing flexible and powerful applications.
              </h2>
            </div>
          </div>
        </div>
        <div className="lg:col-start-2 lg:row-span-2 lg:row-start-1 lg:overflow-hidden">
          {/* <img
            className="w-[48rem] max-w-none rounded-xl bg-gray-900 shadow-xl ring-1 ring-gray-400/10 sm:w-[42rem]"
            src="Image/popularcourse/Learning/html.png"
            alt=""
          /> */}
          <FreeDemoRegistration />
        </div>

        <div className="lg:col-span-2 lg:col-start-1 lg:row-start-2 lg:mx-auto lg:grid lg:w-full lg:max-w-7xl lg:grid-cols-2 lg:gap-x-8 lg:px-8">
          <div className="lg:pr-4">
            <div className="max-w-xl text-base leading-7 text-gray-700 lg:max-w-lg">
              <p>
                Joining Dream Adoration offers multiple benefits because it is
                an versatile learning platform. Gain access to a wide array of
                Courses on different topics, including programming courses. for
                example, languages, web development, data science etc. Interact
                expert instructors, interactive learning materials, and hands-on
                projects that will enhance your skills and help advance your
                career. Whether whether you are a beginner or experienced
                professional, Dream Adoration provides a nurturing environment
                to develop in your learning journey and achieving your goals.
                Join us today and Unleash your potential to be part of the
                technological and innovatory world.
              </p>
            </div>
          </div>
        </div>
      </div>
      <PythonFAQS />
    </div>
  );
}
