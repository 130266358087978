// import { CloudArrowUpIcon, LockClosedIcon, ServerIcon } from '@heroicons/react/20/solid'
import FreeDemoRegistration from "../FreeDemoRegistration";
import FAQSCSS from "../FAQ/FAQSCSS";

export default function CssPage() {
  return (
    <div className="relative isolate overflow-hidden bg-white px-6 py-24 sm:py-32 lg:overflow-visible lg:px-0">
      <div className="absolute inset-0 -z-10 overflow-hidden"></div>
      <div className="mx-auto grid max-w-2xl grid-cols-1 gap-x-8 gap-y-16 lg:mx-0 lg:max-w-none lg:grid-cols-2 lg:items-start lg:gap-y-10">
        <div className="lg:col-span-2 lg:col-start-1 lg:row-start-1 lg:mx-auto lg:grid lg:w-full lg:max-w-7xl lg:grid-cols-2 lg:gap-x-8 lg:px-8">
          <div className="lg:pr-4">
            <div className="lg:max-w-lg">
              <p className="text-base font-semibold leading-7 text-indigo-600">
                Dream Adoration
              </p>
              <h1 className="mt-2 text-3xl font-bold tracking-tight text-gray-900 sm:text-4xl">
                {" "}
                Cascading Style Sheets
              </h1>
              <h2 className="mt-6 text-xl leading-8 text-gray-700">
                The CSS classes available in Dream Adoration are mainly valuable
                for the input concerning Cascading Style Sheets or CSS, which
                will indeed furnish skills in regards to styling and designing
                web pages. Participants from this class become blessed with
                basic knowledge of the CSS syntax, selectors, properties, and
                values, hence enabling one to work upon creating successful,
                responsive web layouts. Whether you're a newcomer in search of
                your first training in web development or an experienced
                professional looking to update your skills, our classes on CSS
                will give you the comprehensive training you need to stay ahead
                of your game. Come and discover the real potential of CSS,
                transforming the beauty and experience of your web applications.
              </h2>
            </div>
          </div>
        </div>
        <div className="lg:col-start-2 lg:row-span-2 lg:row-start-1 lg:overflow-hidden">
          {/* <img
            className="w-[48rem] max-w-none rounded-xl bg-gray-900 shadow-xl ring-1 ring-gray-400/10 sm:w-[42rem]"
            src="Image/popularcourse/Learning/html.png"
            alt=""
          /> */}
          <FreeDemoRegistration />
        </div>

        <div className="lg:col-span-2 lg:col-start-1 lg:row-start-2 lg:mx-auto lg:grid lg:w-full lg:max-w-7xl lg:grid-cols-2 lg:gap-x-8 lg:px-8">
          <div className="lg:pr-4">
            <div className="max-w-xl text-base leading-7 text-gray-700 lg:max-w-lg">
              <p>
                Many advantages are offered by joining Dream Adoration since it
                becomes a versatile learning platform, through which several
                courses in numerous topics - programming languages, web
                development, data science, etc., will be approached. Expert
                instructors, interactive learning materials, and hands-on
                projects to enhance your skills and advance your career. Whether
                you are a novice or an experienced professional, the encouraging
                environment fostered by Dream Adoration can help strengthen the
                learning journey towards the pursuit of your goals. Tune in with
                us today and discover your potential in this world of technology
                and innovation.
              </p>
            </div>
          </div>
        </div>
      </div>
      <FAQSCSS />
    </div>
  );
}
