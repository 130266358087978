import React, { useState } from "react";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import "./CSS/testimonial.css";

const data = [
  {
    name: "Ankita Mahapatra",
    img: "Image/testimonials/avatar.png",
    alt: "image1",
    review:
      "The Dream Adoration site provides value feedback for employees of the platform. It's a learning site, catalyzing growth and development of employees through a culture of continuous improvement. Employees develop the quality of their own skills and, therefore, the effectiveness of the service by using constructive feedback, eventually driving professional advancement and organizational success.",
  },
  {
    name: "Shalu Kumari",
    img: "Image/testimonials/human.png",
    alt: "image1",
    review:
      "Employees of Dream Adoration find this knowledge development tool quite helpful in building expertise at its level. The interactive learning environment fosters a culture where people constantly try to improve their knowledge and share it with their colleagues. It uses very friendly tools having personalized pathways for learning, which empowers staff to try for new opportunities, build abilities, and therefore succeed at work.",
  },
  {
    name: "Gaurav Kumar Ojha",
    img: "Image/testimonials/man.png",
    alt: "image1",
    review:
      "Really, it has been a great interaction experience on our part; Dream Adoration brings a seamless learning journey, because resources and tools are abundant. It is such an important and supportive community that offers wonderful, engaging content for growth and development. Really, Dream Adoration is a catalyst for continuous learning and career advancement.",
  },
  {
    name: "Mohit Pant",
    img: "Image/testimonials/people.png ",
    alt: "image1",
    review:
      "Dream Adoration provides for an open setting where workers seek and give feedback. Such an exciting circle of feedback ensures that one learns and develops in the course of producing more capable workers into facilitating the achievement of the overall success of the site. Open communication and delivery of diverse opinions empower workers at work to their fuller potential and drive innovation in the organisation.",
  },
];

function Testimonials() {
  const [expandedIndex, setExpandedIndex] = useState(-1);
  const settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 3, // Initially show 3 slides
    slidesToScroll: 1,
    responsive: [
      {
        breakpoint: 1024, // Breakpoint for desktop
        settings: {
          slidesToShow: 3, // Show 3 slides on desktop
        },
      },
      {
        breakpoint: 768, // Breakpoint for tablet/mobile
        settings: {
          slidesToShow: 1, // Show 1 slide on tablet/mobile
        },
      },
    ],
  };

  const toggleExpand = (index) => {
    setExpandedIndex(index === expandedIndex ? -1 : index); // Toggle expanded state
  };

  return (
    <div
      className="testimonial-container"
      style={{
        backgroundColor: "#0d1232",
        padding: "2rem",
        margin: "3rem",
        borderRadius: "5px",
        textAlign: "center",
      }}
    >
      <h2 className="text-white" style={{ fontSize: "18px" }}>
        Testimonial
        <br />
        <span style={{ fontSize: "2rem" }}>
          Don't just take it <span style={{ color: "red" }}>from us</span>
        </span>
      </h2>
      <div className="w-3/4 m-auto">
        <div className="mt-10">
          <Slider {...settings}>
            {data.map((d, index) => (
              <div
                key={index}
                className="slide-testimonial bg-white text-black rounded-xl overflow-hidden"
                style={{ height: "400px" }}
              >
                {/* Set fixed height */}
                <div className="h-46 rounded-t-xl bg-indigo-500 flex justify-center items-center">
                  <img src={d.img} alt="" className="h-44 w-44 rounded-full" />
                </div>
                <div className="flex flex-col justify-center items-center gap-1 p-4 overflow-auto">
                  {/* Add overflow-auto */}
                  <p className="text-xl font-semibold">{d.name}</p>
                  {expandedIndex === index ? (
                    <p>{d.review}</p>
                  ) : (
                    <p>
                      {d.review.length > 80
                        ? d.review.slice(0, 80) + "..."
                        : d.review}
                    </p> // Render shortened review
                  )}
                  <button
                    onClick={() => toggleExpand(index)}
                    className="bg-indigo-500 text-white text-lg px-6 py-1 rounded-xl read-more-button"
                  >
                    {expandedIndex === index ? "Read Less" : "Read More"}
                  </button>
                </div>
              </div>
            ))}
          </Slider>
        </div>
      </div>
    </div>
  );
}

export default Testimonials;
