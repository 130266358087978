// import { CloudArrowUpIcon, LockClosedIcon, ServerIcon } from '@heroicons/react/20/solid'
import FreeDemoRegistration from "../FreeDemoRegistration";
import JavaScriptFAQS from "../FAQ/JavaScripFAQS";

export default function JavaScriptPage() {
  return (
    <div className="relative isolate overflow-hidden bg-white px-6 py-24 sm:py-32 lg:overflow-visible lg:px-0">
      <div className="absolute inset-0 -z-10 overflow-hidden"></div>
      <div className="mx-auto grid max-w-2xl grid-cols-1 gap-x-8 gap-y-16 lg:mx-0 lg:max-w-none lg:grid-cols-2 lg:items-start lg:gap-y-10">
        <div className="lg:col-span-2 lg:col-start-1 lg:row-start-1 lg:mx-auto lg:grid lg:w-full lg:max-w-7xl lg:grid-cols-2 lg:gap-x-8 lg:px-8">
          <div className="lg:pr-4">
            <div className="lg:max-w-lg">
              <p className="text-base font-semibold leading-7 text-indigo-600">
                Dream Adoration
              </p>
              <h1 className="mt-2 text-3xl font-bold tracking-tight text-gray-900 sm:text-4xl">
                JavaScript
              </h1>
              <h2 className="mt-6 text-xl leading-8 text-gray-700">
                Dream Adoration offers JavaScript classes to educate the
                audience and inform the foundation of JavaScript that has become
                a must-have to integrate more interactive or dynamic behavior
                with web pages. Thus, classes in the JavaScript syllabus will
                teach their participants basic JavaScript syntax, data types,
                functions, and event handling so that they can build interactive
                and responsive web applications. Whether you are a beginner
                wanting to start here or an advanced professional looking to
                update your skill set, our classes in JavaScript will give you
                the right learning experience that fits your needs. Join us and
                unlock the full potential of JavaScript to build interactive web
                applications.
              </h2>
            </div>
          </div>
        </div>
        <div className="lg:col-start-2 lg:row-span-2 lg:row-start-1 lg:overflow-hidden">
          {/* <img
            className="w-[48rem] max-w-none rounded-xl bg-gray-900 shadow-xl ring-1 ring-gray-400/10 sm:w-[42rem]"
            src="Image/popularcourse/Learning/html.png"
            alt=""
          /> */}
          <FreeDemoRegistration />
        </div>

        <div className="lg:col-span-2 lg:col-start-1 lg:row-start-2 lg:mx-auto lg:grid lg:w-full lg:max-w-7xl lg:grid-cols-2 lg:gap-x-8 lg:px-8">
          <div className="lg:pr-4">
            <div className="max-w-xl text-base leading-7 text-gray-700 lg:max-w-lg">
              <p>
                There are several benefits associated with the joining process
                to Dream Adoration since this is an adaptive learning platform.
                Access courses on various subjects from programming languages to
                web development, data science, and more. Learn with expert
                instructors, interactive learning materials, and hands-on
                projects so as to enhance your skills and advancement in your
                career. Whether you're a tech newbie or old pro, Dream Adoration
                creates a space for nurturing your learning journey and to help
                you get what you need to grow. Join us today and unlock your
                opportunities in the future world of technology and innovation.
              </p>
            </div>
          </div>
        </div>
      </div>
      <JavaScriptFAQS />
    </div>
  );
}
