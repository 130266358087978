// import { CloudArrowUpIcon, LockClosedIcon, ServerIcon } from '@heroicons/react/20/solid'
import FreeDemoRegistration from "../FreeDemoRegistration";
import PHPFAQS from "../FAQ/PHPFAQS";

export default function PhpPage() {
  return (
    <div className="relative isolate overflow-hidden bg-white px-6 py-24 sm:py-32 lg:overflow-visible lg:px-0">
      <div className="absolute inset-0 -z-10 overflow-hidden"></div>
      <div className="mx-auto grid max-w-2xl grid-cols-1 gap-x-8 gap-y-16 lg:mx-0 lg:max-w-none lg:grid-cols-2 lg:items-start lg:gap-y-10">
        <div className="lg:col-span-2 lg:col-start-1 lg:row-start-1 lg:mx-auto lg:grid lg:w-full lg:max-w-7xl lg:grid-cols-2 lg:gap-x-8 lg:px-8">
          <div className="lg:pr-4">
            <div className="lg:max-w-lg">
              <p className="text-base font-semibold leading-7 text-indigo-600">
                Dream Adoration
              </p>
              <h1 className="mt-2 text-3xl font-bold tracking-tight text-gray-900 sm:text-4xl">
                PHP
              </h1>
              <h2 className="mt-6 text-xl leading-8 text-gray-700">
                At Dream Adoration, PHP classes help build a good ground in the
                use of essential languages in web development, including PHP
                classes. Basic concepts are taught at this level, such as
                syntax, data types, functions, and how to handle events, able to
                empower participants to create dynamic and interactive web
                applications. Whether you are stepping into web development for
                the first time or want to add depth to your existing skills, our
                classes on PHP allow you to dive into a comprehensive learning
                experience. Join us and discover the full potential of PHP for
                the creation of fascinating interactive web applications.
              </h2>
            </div>
          </div>
        </div>
        <div className="lg:col-start-2 lg:row-span-2 lg:row-start-1 lg:overflow-hidden">
          {/* <img
            className="w-[48rem] max-w-none rounded-xl bg-gray-900 shadow-xl ring-1 ring-gray-400/10 sm:w-[42rem]"
            src="Image/popularcourse/Learning/html.png"
            alt=""
          /> */}
          <FreeDemoRegistration />
        </div>

        <div className="lg:col-span-2 lg:col-start-1 lg:row-start-2 lg:mx-auto lg:grid lg:w-full lg:max-w-7xl lg:grid-cols-2 lg:gap-x-8 lg:px-8">
          <div className="lg:pr-4">
            <div className="max-w-xl text-base leading-7 text-gray-700 lg:max-w-lg">
              <p>
                Joining Dream Adoration would bring numerous benefits since it
                is a versatile learning site. Access hundreds of courses on
                different aspects, including programming languages, web
                development, data science, and so much more. Work with the best
                instructors along with interactive learning materials and
                hands-on projects to improve your skills and further your
                career. Whether you're a beginner or an experienced
                professional, Dream Adoration provides a supportive environment
                to foster your learning journey and achieve your goals. Join us
                today and unlock your potential in the world of technology and
                innovation.
              </p>
            </div>
          </div>
        </div>
      </div>
      <PHPFAQS />
    </div>
  );
}
