import React from "react";
import htmlimg from "../Image/Book/php.png";
import DownloadEbookForm from "../DownloadEbookForm";

function PhpBook() {
  return (
    <div
      style={{
        margin: "2rem",
        marginTop: "5rem",
        display: "flex",
        flexDirection: "column",
        alignItems: "center", // Center content horizontally
      }}
    >
      {/* Card */}
      <div
        style={{
          maxWidth: "100%",
          overflow: "hidden",
          marginBottom: "2rem",
          width: "100%",
        }}
      >
        <div
          className="flex flex-col items-center bg-white rounded-lg shadow md:flex-row hover:bg-gray-100 dark:border-gray-700 dark:bg-gray-800 dark:hover:bg-gray-700"
          style={{
            display: "flex",
            textDecoration: "none",
            color: "inherit",
          }}
        >
          <img
            className="object-cover w-full rounded-t-lg md:w-96 rounded-l-lg"
            src={htmlimg}
            alt=""
          />
          <div
            className="flex flex-col justify-between p-4 leading-normal"
            style={{ flex: "1" }}
          >
            <h1 className="mb-1 text-2xl font-bold text-gray-900 dark:text-white">
              PHP
            </h1>
            <h2 className="mb-3 text-xl font-bold text-gray-700 dark:text-gray-400">
              Author : WCGs serve
            </h2>
            <p className="mb-3 font-normal text-gray-600 dark:text-gray-400">
              Dive into the dynamic Welcome to world of web development with
              'PHP Powerhouse.' Unscramble the power and flexibility of PHP, the
              spine of millions of websites and web applications. This
              comprehensive guide equips you with the skills and knowledge to
              take fullest potential from PHP, from basics scripting to advanced
              web development techniques, learn how to Develop dynamic websites,
              interacting with databases and creating robust web applications.
              It doesn't matter if you are a junior or a; Experienced PHP
              developer, 'PHP Powerhouse' is your bible for mastering PHP and
              creating dynamic web experiences. Elevated End Coding skills, the
              unleashing of creativity, and actualization of potential. of PHP
              with 'PHP Powerhouse'.
            </p>

            <DownloadEbookForm />
          </div>
        </div>
      </div>

      {/* Download Form */}
      <div style={{ width: "100%", maxWidth: "500px" }}>
        {/* <DownloadEbookForm /> */}
      </div>
    </div>
  );
}

export default PhpBook;
