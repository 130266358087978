// import { CloudArrowUpIcon, LockClosedIcon, ServerIcon } from '@heroicons/react/20/solid'
import FreeDemoRegistration from "../FreeDemoRegistration";
import MysqlFAQS from "../FAQ/MysqlFAQS";

export default function MysqlPage() {
  return (
    <div className="relative isolate overflow-hidden bg-white px-6 py-24 sm:py-32 lg:overflow-visible lg:px-0">
      <div className="absolute inset-0 -z-10 overflow-hidden"></div>
      <div className="mx-auto grid max-w-2xl grid-cols-1 gap-x-8 gap-y-16 lg:mx-0 lg:max-w-none lg:grid-cols-2 lg:items-start lg:gap-y-10">
        <div className="lg:col-span-2 lg:col-start-1 lg:row-start-1 lg:mx-auto lg:grid lg:w-full lg:max-w-7xl lg:grid-cols-2 lg:gap-x-8 lg:px-8">
          <div className="lg:pr-4">
            <div className="lg:max-w-lg">
              <p className="text-base font-semibold leading-7 text-indigo-600">
                Dream Adoration
              </p>
              <h1 className="mt-2 text-3xl font-bold tracking-tight text-gray-900 sm:text-4xl">
                MYSQL
              </h1>
              <h2 className="mt-6 text-xl leading-8 text-gray-700">
                In Dream Adoration, MySQL classes are offered to give a stronger
                understanding of MySQL as it supports the management of any
                database during web development. As participants attend the
                classes, they learn how to obtain and manipulate MySQL,
                including learning how to create databases, as well as querying
                and optimisation techniques. Whether you're just beginning in
                database management or want to complement existing skills, our
                MySQL classes promise comprehensive learning tailored to your
                requirements. Join us to understand and unlock the true power of
                MySQL in optimizing data storage and retrieval for your web
                applications.
              </h2>
            </div>
          </div>
        </div>
        <div className="lg:col-start-2 lg:row-span-2 lg:row-start-1 lg:overflow-hidden">
          {/* <img
            className="w-[48rem] max-w-none rounded-xl bg-gray-900 shadow-xl ring-1 ring-gray-400/10 sm:w-[42rem]"
            src="Image/popularcourse/Learning/html.png"
            alt=""
          /> */}
          <FreeDemoRegistration />
        </div>

        <div className="lg:col-span-2 lg:col-start-1 lg:row-start-2 lg:mx-auto lg:grid lg:w-full lg:max-w-7xl lg:grid-cols-2 lg:gap-x-8 lg:px-8">
          <div className="lg:pr-4">
            <div className="max-w-xl text-base leading-7 text-gray-700 lg:max-w-lg">
              <p>
                By joining Dream Adoration, one benefits as it is a very broad
                learning platform. Its courses range from many different topics
                and subjects: programming languages, web development, data
                science, and much more. Engage with expert instructors,
                interactive learning materials, hands-on projects, and more, to
                enhance your skills and advance your career. From novice to
                expert, Dream Adoration is there to help in the day-to-day
                processes that enhance the learning journey and accomplish the
                goals. Sign up today and get yourself set loose into technology
                and innovation.
              </p>
            </div>
          </div>
        </div>
      </div>
      <MysqlFAQS />
    </div>
  );
}
