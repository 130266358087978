import React from "react";
import htmlimg from "../Image/Book/html.jpg";
import DownloadEbookForm from "../DownloadEbookForm";

function HtmlBook() {
  return (
    <div
      style={{
        margin: "2rem",
        marginTop: "5rem",
        display: "flex",
        flexDirection: "column",
        alignItems: "center", // Center content horizontally
      }}
    >
      {/* Card */}
      <div
        style={{
          maxWidth: "100%",
          overflow: "hidden",
          marginBottom: "2rem",
          width: "100%",
        }}
      >
        <div
          className="flex flex-col items-center bg-white rounded-lg shadow md:flex-row hover:bg-gray-100 dark:border-gray-700 dark:bg-gray-800 dark:hover:bg-gray-700"
          style={{
            display: "flex",
            textDecoration: "none",
            color: "inherit",
          }}
        >
          <img
            className="object-cover w-full rounded-t-lg md:w-96 rounded-l-lg"
            src={htmlimg}
            alt=""
          />
          <div
            className="flex flex-col justify-between p-4 leading-normal"
            style={{ flex: "1" }}
          >
            <h1 className="mb-1 text-2xl font-bold text-gray-900 dark:text-white">
              HTML Mastery Unleashing the Power of Web Design
            </h1>
            <h2 className="mb-3 font-bold text-xl text-gray-700 dark:text-gray-400">
              Author : Aman Kumar
            </h2>
            <p className="mb-3 font-normal text-gray-600 dark:text-gray-400">
              Aman Kumar's the HTML Mastery: Unleashing the Power of Web Design
              ends a definitive guide for anyone interested in creating dynamic
              and Engaging websites using simple HTML, the building blocks of
              web development. This takes the reader from simple HTML tags to
              advanced concepts like semantic HTML, multimedia integration, and
              forms. Designed to suit beginners and professionals, these are
              equipped with the skills to structure your web content effectively
              and enhance user experiences. Whether you're building a small
              webpage or a complex application, this book will help you unlock
              the true potential of. The potential of HTML in modern web design.
            </p>

            <DownloadEbookForm />
          </div>
        </div>
      </div>

      {/* Download Form */}
      <div style={{ width: "100%", maxWidth: "500px" }}>
        {/* <DownloadEbookForm /> */}
      </div>
    </div>
  );
}

export default HtmlBook;
