import React from "react";
import htmlimg from "../Image/Book/css.png";
import DownloadEbookForm from "../DownloadEbookForm";

function CssBook() {
  return (
    <div
      style={{
        margin: "2rem",
        marginTop: "5rem",
        display: "flex",
        flexDirection: "column",
        alignItems: "center", // Center content horizontally
      }}
    >
      {/* Card */}
      <div
        style={{
          maxWidth: "100%",
          overflow: "hidden",
          marginBottom: "2rem",
          width: "100%",
        }}
      >
        <div
          className="flex flex-col items-center bg-white rounded-lg shadow md:flex-row hover:bg-gray-100 dark:border-gray-700 dark:bg-gray-800 dark:hover:bg-gray-700"
          style={{
            display: "flex",
            textDecoration: "none",
            color: "inherit",
          }}
        >
          <img
            className="object-cover w-full rounded-t-lg md:w-96 rounded-l-lg"
            src={htmlimg}
            alt=""
          />
          <div
            className="flex flex-col justify-between p-4 leading-normal"
            style={{ flex: "1" }}
          >
            <h1 className="mb-1 text-2xl font-bold text-gray-900 dark:text-white">
              Mastering CSS for Modern Web Design
            </h1>
            <h2 className="mb-3 font-bold text-gray-700 text-xl dark:text-gray-400">
              Author : Aman Kumar
            </h2>
            <p className="mb-3 font-normal text-gray-600 dark:text-gray-400">
              Your guide to mastering CSS for modern web design is Aman Kumar.
              Unleash the potential of CSS in creating attractive results.
              Amazing, interactive and responsive websites. This book is known
              from the basics of CSS to advanced techniques like Flexbox, Grid,
              animations, and transitions, all emphasizing Best practices for
              the modern web. Whether you're a newcomer or a seasoned developer
              to understand, the material is well-explained here. More practical
              examples to help you design elegant, user-friendly interfaces.
              Elevate your web development skills and transform your Design your
              vision into reality with CSS mastery!
            </p>

            <DownloadEbookForm />
          </div>
        </div>
      </div>

      {/* Download Form */}
      <div style={{ width: "100%", maxWidth: "500px" }}>
        {/* <DownloadEbookForm /> */}
      </div>
    </div>
  );
}

export default CssBook;
