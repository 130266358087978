import React from "react";
import { Helmet } from "react-helmet";
function EditorMeta() {
  return (
    <div>
      <Helmet>
        <title>Dream Adoration: Online Editor</title>
        <meta
          name="description"
          content="Online editor tool for fast and easy text editing, formatting, and document customization. Real-time edit, formatting, and enrichment of content with our user-friendly, web-based text editor designed to accommodate efficient writing and editing tasks."
        />
        <meta
          name="keywords"
          content="Online editor tool for fast and easy text editing, formatting, and document customization. Real-time edit, formatting, and enrichment of content with our user-friendly, web-based text editor designed to accommodate efficient writing and editing tasks."
        />
        <meta name="author" content="Dream Adoration" />
        <link rel="canonical" href="https://www.dreamadoration.com/editor" />

        {/* Open Graph / Facebook */}
        <meta property="og:type" content="article" />
        <meta property="og:title" content="Dream Adoration: Online Editor" />
        <meta
          property="og:description"
          content="Online editor tool for fast and easy text editing, formatting, and document customization. Real-time edit, formatting, and enrichment of content with our user-friendly, web-based text editor designed to accommodate efficient writing and editing tasks."
        />
        <meta
          property="og:image"
          content="https://dreamadoration.com/static/media/Dreamadoration.00373888822043504889.png"
        />
        <meta
          property="og:image:secure_url"
          content="https://dreamadoration.com/static/media/Dreamadoration.00373888822043504889.png"
        />
        <meta property="og:image:alt" content="Dream Adoration Online Editor" />
        <meta property="og:url" content="https://dreamadoration.com/editor" />
        <meta property="og:site_name" content="Dream Adoration" />
        <meta
          property="article:publisher"
          content="https://www.facebook.com/profile.php?id=61560307265465"
        />

        {/* Twitter */}
        <meta name="twitter:card" content="summary_large_image" />
        <meta name="twitter:title" content="Dream Adoration: Online Editor" />
        <meta
          name="twitter:description"
          content="Online editor tool for fast and easy text editing, formatting, and document customization. Real-time edit, formatting, and enrichment of content with our user-friendly, web-based text editor designed to accommodate efficient writing and editing tasks."
        />
        <meta
          name="twitter:image"
          content="https://dreamadoration.com/static/media/ITNewsBlogCoverImage.png"
        />
        <meta name="twitter:site" content="@dreamadoration" />
        <meta name="twitter:creator" content="@dreamadoration" />
      </Helmet>
    </div>
  );
}

export default EditorMeta;
