// import { CloudArrowUpIcon, LockClosedIcon, ServerIcon } from '@heroicons/react/20/solid'
import FreeDemoRegistration from "../FreeDemoRegistration";
import ReactJSFAQS from "../FAQ/ReactJSFAQS";

export default function ReactJsPage() {
  return (
    <div className="relative isolate overflow-hidden bg-white px-6 py-24 sm:py-32 lg:overflow-visible lg:px-0">
      <div className="absolute inset-0 -z-10 overflow-hidden"></div>
      <div className="mx-auto grid max-w-2xl grid-cols-1 gap-x-8 gap-y-16 lg:mx-0 lg:max-w-none lg:grid-cols-2 lg:items-start lg:gap-y-10">
        <div className="lg:col-span-2 lg:col-start-1 lg:row-start-1 lg:mx-auto lg:grid lg:w-full lg:max-w-7xl lg:grid-cols-2 lg:gap-x-8 lg:px-8">
          <div className="lg:pr-4">
            <div className="lg:max-w-lg">
              <p className="text-base font-semibold leading-7 text-indigo-600">
                Dream Adoration
              </p>
              <h1 className="mt-2 text-3xl font-bold tracking-tight text-gray-900 sm:text-4xl">
                React JS
              </h1>
              <h2 className="mt-6 text-xl leading-8 text-gray-700">
                Dream Adoration provides courses on React.js for the basic
                understanding of React.js-an important aspect in dynamic and
                interactive front-end developments. In these courses,
                participants are learned the basic concepts of React.js
                including JSX syntax, architecture of the components, how to
                manage the state, and lifecycle methods so that they can create
                efficient modern, responsive, and eye-friendly web applications.
                Join us in discovering the powerful realm of React.js, building
                skills, and shaping ideas into potentially mesmerizing
                interactive web applications. Whether you're a new comer to web
                development or are an experienced developer looking for
                high-quality training, our courses on React.js will provide an
                experience that is tailored just for you.
              </h2>
            </div>
          </div>
        </div>
        <div className="lg:col-start-2 lg:row-span-2 lg:row-start-1 lg:overflow-hidden">
          {/* <img
            className="w-[48rem] max-w-none rounded-xl bg-gray-900 shadow-xl ring-1 ring-gray-400/10 sm:w-[42rem]"
            src="Image/popularcourse/Learning/html.png"
            alt=""
          /> */}
          <FreeDemoRegistration />
        </div>

        <div className="lg:col-span-2 lg:col-start-1 lg:row-start-2 lg:mx-auto lg:grid lg:w-full lg:max-w-7xl lg:grid-cols-2 lg:gap-x-8 lg:px-8">
          <div className="lg:pr-4">
            <div className="max-w-xl text-base leading-7 text-gray-700 lg:max-w-lg">
              <p>
                There are various advantages of joining Dream Adoration as this
                is a versatile learning platform. Seek access to a wide array of
                programming among many other courses languages, web development,
                data science, and more. Interact with expert instructors,
                interactive learning materials, and hands-on projects to enhance
                your skills and advance your career. Whether you're a beginner
                or an experienced professional, Dream Adoration helps offer a
                nurturing atmosphere to nurture your learning journey and
                achieve your goals. Join us today and Unlock your potential in
                the world of technology and innovation.
              </p>
            </div>
          </div>
        </div>
      </div>
      <ReactJSFAQS />
    </div>
  );
}
